import React from 'react';
import Header from '../components/Header';

import Footer from '../components/Footer';
import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout>
    <section>
        <div className="container">
          <Header />
          <h2>
            Terms Of Use
          </h2>
          <p>
            David Hunt (Nearo) grants you a non-exclusive, limited and revocable licence to use and access the Nearo mobile application 
            (Services) subject to these terms and conditions (Terms).
          </p>
          <p>
            In these Terms, “we”, “us” and “our” refer to David Hunt (Nearo) and references to “you” and “your” is to you, the user of the Service.
          </p>
          <p>
            By signing up and using this service you acknowledge and agree to be bound by these terms.
            You will use the service for no purpose other than to view/create and interact with posts.
          </p>
            <p>
            You will respect the privacy of people with whom you come into contact in the course of 
            using this service.
            </p>
            <p>
            You are required to register to use this service, this is through one of the designated authentication providers
            (Facebook, Google, App). You are required to keep such details up to date for your continued use of the service, 
            to not share your password with anyone and to otherwise keep your password and any other security related information safe and secure.
            </p>
            <p>
            You undertake to respect other users and not to act in any way that could be commonly understood 
            as offensive or abusive.
            </p>
            <p>
            You undertake that you will not post content of any manner that is commonly understood to be 
            inappropriate or that contravenes law.
            </p>
            <p>
            Users may report content they see as inappropriate to <a href="mailto:info@nearo.co">info@nearo.co </a> or
            via the Nearo mobile application. Our judgement on whether content is appropriate is final.
            </p>
            <p>
            We reserve the right to remove any content that we deem as being inappropriate or to block any user that 
            we deem as having breached our policy and guidelines or any law.
            </p>
            <p>
            We will do all in our power to act on objectionable content reports within 24 hours by removing the content and ejecting the user who provided the offending content.
            </p>
            <p>
            Notwithstanding that we will do all in our power to limit inappropriate content, we cannot completely
            monitor all use of this service. By using the service you accept that Nearo provides no warranties of 
            any kind with respect to the service or content that is accessible through the service and neither the owners 
            nor operators of this website nor the designers nor any other person or organisation participating with us, 
            nor any of their officers, employees or agents will be liable to you for any direct or indirect loss or consequential
            loss or damage arising from your access or use of the service and any such liability is excluded to the maximum extent permitted by law.
            </p>
            <p>
            You allow us to use the content that you post in the App (“User Generated Content”) (in particular content that is 
            protected by intellectual property rights) in order for us to provide you with the respective App services in the 
            App (in this context and for such purposes we are, in particular, allowed to host, use, distribute, modify, perform, 
            reproduce, copy, publicly perform or display, translate and create derivative works of the User Generated Content.
            The license in the aforementioned sentence is granted to us cost free and for the purpose of providing you with the App services.
            </p>
            <p>
            We may also use the User Generated Content on our social media pages (e.g. Facebook, Instagram, Twitter) and /or use them in
            (connection with) other publications of us (e.g. books) that we might produce and distribute without any further
            consideration owed to you.
            </p>
            <p>
            Either party may terminate use of the service at any time without need for justification.
            </p>
            <p>
            You acknowledge that the appearance of any links to access third party websites that may occur within the
            service does not infer any responsibility on Nearo for their content.
            </p>
            <p>
            We reserve the right to change any aspect of the service or content at our sole discretion without notice.
            </p>
            <p>
            You accept that this agreement is governed by the Laws of Ireland and any recourse to law will be under the 
            jurisdiction of the Courts of Ireland.
            </p>
            <p>
            We will use the information you give us on the platform for no other purpose than facilitating the services of the app,
            unless required to do so by law or where we work with other organisations in order to 
            facilitate these services. We will require any such other parties to adhere to these terms.
            </p>
            <p>
            You warrant that the content is copyright free or is created by you and or is owned by you, such that you have the right
            to grant licence to Nearo to use it.
            </p>
            <p>
            If you believe that your content has been placed on the service without your permission, please contact info@earo.co.
            </p>
            <p>
              You accept the terms of our Privacy policy.
            </p>
            <p>
              We anticipate regular reviews of this page. Any changes will be placed on this webpage and we recommend
              that you check back on this page for the current terms and conditions. This document was first published on 
              04/11/20 and last updated on 04/11/20.
          </p>
        </div>
    </section>
    <Footer />
  </Layout>
);

export default IndexPage;
